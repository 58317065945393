import { Cancel } from '@mui/icons-material'
import React from 'react'
import { Link } from 'react-router-dom'

function Mobhd({vw1, vw2, vw3, vw4, vw5, vw6, vw7, vw8, menubtn}) {
  return (
    <div className='mobbnavlinks' onClick={menubtn}>
            <div className='monavlink'>
              <div onClick={menubtn} className='mobcancel'>
                <Cancel/>
              </div>

              <div className='mobhdlinks'>
                <a className={vw1 ? 'hdlnk1' : 'hdlnk2'} href='/' >Home</a>

                <a className={vw2 ? 'hdlnk1' : 'hdlnk2'} href='#about' >About</a>

                <a className={vw3 ? 'hdlnk1' : 'hdlnk2'} href='#features' >Features</a>

                <a className={vw4 ? 'hdlnk1' : 'hdlnk2'} href='#investments' >Investments</a>

                <a className={vw5 ? 'hdlnk1' : 'hdlnk2'} href='#team' >Team</a>

                <a className={vw6 ? 'hdlnk1' : 'hdlnk2'} href='#faqs' >FAQ</a>

                <a className={vw7 ? 'hdlnk1' : 'hdlnk2'} href='#reviews' >Our Reviews</a>

                <a className={vw8 ? 'hdlnk1' : 'hdlnk2'} href='#contact' >Contact Us</a>
              </div>

              <div className='mobbuttons'>
                <Link to='/login' className='btnl'>Login</Link>
                <Link to='/register' className='btnl'>Register</Link>
              </div>
            </div>
      </div>
  )
}

export default Mobhd