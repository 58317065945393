import React from 'react'
import './header.css'
import { Link } from 'react-router-dom'
import { Menu } from '@mui/icons-material'
import lg from './img/logo.png'
import Ticker from './Loged/widgets/Ticker'



function Header({vw1, vw2, vw3, vw4, vw5, vw6, vw7, vw8, menu, menubtn}) {
  return (
   <div className='header' data-aos ='zoom-in'>
      <div className='desktophd'>
        <img style={{width:'12em', height:'6em'}} src={lg} alt='' />
        <a className={vw1 ? 'hdlnk1' : 'hdlnk2'} href='/' >Home</a>

        <a className={vw2 ? 'hdlnk1' : 'hdlnk2'} href='#about' >About</a>

        <a className={vw3 ? 'hdlnk1' : 'hdlnk2'} href='#features' >Features</a>

        <a className={vw4 ? 'hdlnk1' : 'hdlnk2'} href='#investments' >Investments</a>

        <a className={vw5 ? 'hdlnk1' : 'hdlnk2'} href='#team' >Team</a>

        <a className={vw6 ? 'hdlnk1' : 'hdlnk2'} href='#faqs' >FAQ</a>

        <a className={vw7 ? 'hdlnk1' : 'hdlnk2'} href='#reviews' > Reviews</a>

        <a className={vw8 ? 'hdlnk1' : 'hdlnk2'} href='#contact' >Contact Us</a>

        <div className='buttons'>
          <Link to='/login' className='btnl'>Login</Link>
          <Link to='/register' className='btnl'>Register</Link>
        </div>
      </div>

      <div className='mobbheader'>
        <div className='mobbhead'>
          <img style={{width:'4em', height:'4em'}}  src={lg} alt='' />

          {
            menu ?
            null
            :
            <div onClick={menubtn} className='mobbmenu'>
              <Menu/>
            </div>
          }
        </div>
      </div>

        

      <div className='hdcrp'>
        <Ticker/>
      </div>
   </div>
  )
}

export default Header